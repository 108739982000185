import React, { useState, createRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { RiCloseCircleLine } from 'react-icons/ri';
import { popupAnimation } from '../../utils/animations';
import Modal from '../Modal';
import * as features from './constants';
import styles from './Courses.module.scss';

const courses = [
    {
        id: 6,
        title: 'Оптимальний-2025',
        price: '1500 грн/міс',
        options: [
            'Реєстрація: закрита',
            'Тривалість: до дня НМТ',
            'Кількість: 3 уроки на тиждень',
            'Навчання: з 1 жовтня'
        ],
        about: {
            title: 'Курс «Оптимальний» по підготовці до НМТ з математики',
            content: `Курс «Оптимальний» - це оптимальний курс для підготовки до НМТ з математики. Оптимальні: об’єм інформації, кількість домашніх завдань, контроль викладача, кількість учнів в міні-групі, ціна, підтримка викладача.
            Що ви отримаєте, придбавши курс? Кожного тижня нова тема, три заняття на тиждень – два в записі і одне повноцінне наживо. Робота в міні-групі (4-5 учнів) з повноцінною підтримкою та індивідуальним підходом до кожного. 
            Перевірка та аналіз домашніх завдань. Робочі зошити та всі необхідні матеріали. Підсумкові тести та симуляції НМТ. Психологічна підтримка та повноцінна мотивація. Помісячна оплата.
            Робота в міні-групі дозволяє комунікувати викладачу з кожним учнем окремо і конкурувати учням між собою одночасно!
            «Оптимальний» - це оптимальне рішення, якщо плануєш скласти математику на 200!`
        },
        payLink: 'https://www.ipay.ua/ua/bills/online-kursy-podgotovki-k-vno-po-matematike-online',
        features: [
            features.INDIVIDUAL,
            features.VIDEO,
            features.MATERIALS,
            features.TEST_TASKS,
            features.PREPARATION,
            features.SUPPORT,
            features.CHECK,
            features.HOME_WORK,
            features.REPEAT
        ]
    },
    {
        id: 2,   
        title: 'Тренувальний',
        price: '299 грн',
        options: [
            'Реєстрація:закрита',
            'Тривалість: до дня НМТ',
            'Кількість: 15 симуляцій НМТ',
            'Навчання: після реєстрації'
        ],
        about: {
            title: 'Онлайн-курс «Тренувальний» по підготовці до НМТ з математики',
            content: `Якщо ви готуєтеся до НМТ з математики, маєте певну базу але не вистачає практики, то цей курс саме для вас! 
            15 авторських симуляцій НМТ та моя підтримка онлайн допоможуть вам вдосконалити свої знання, успішно скласти НМТ з математики, та вступити в омріяний ВНЗ!
            Всього за 299 гривень ви зможете перевірити себе, та потренувати складання НМТ!`
        },
        payLink: 'https://www.ipay.ua/ua/bills/online-kursy-podgotovki-k-vno-po-matematike-online',
        features: [
            features.INDIVIDUAL,
            features.VIDEO1,
            features.PREPARATION,
            features.QUESTIONS,
            features.SUPPORT1,
        ]
    },
    {
        id: 5,
        title: 'Математика з нуля',
        price: '499 грн',
        options: [
            'Реєстрація: відкрита',
            'Тривалість: 3 місяці',
            'Кількість: 45 міні-уроків',
            'Навчання:  одразу (не НМТ)'
        ],
        about: { 
            title: 'Онлайн-курс «Математика з нуля»',
            content: `Курс «Математика з нуля» складається з 45 міні-уроків в записі, та розрахований на інтенсивне відновлення знань учнями, здобутих впродовж 6-8 класів. 
            Курс підходить для тих, хто вважає що математика "не його", що він "гуманітарій" і складні математичні істини ніколи не стануть зрозумілими. Розвіюємо міфи лише за 3 місяці. 
            Курс складається з міні-уроків тривалістю 20-25 хвилин в записі, кожен міні-урок включає міні-лекцію (6-7 хв.), 5 тестів на відпрацювання вмінь, та відеоаналіз до них. 
            Курс підходить не лише для тих, хто готується до НМТ, а й для учнів 6-9 класів та їх батьків. Курс не є повноцінною підготовкою до НМТ!`
        },
        payLink: 'https://www.ipay.ua/ua/bills/online-kursy-podgotovki-k-vno-po-matematike-online',
        features: [
            features.INDIVIDUAL,
            features.VIDEO2,
            features.TEST_TASKS,
            features.PREPARATION,
            features.QUESTIONS,
        ]
    },
];

const specialOffers = [
    {
        id: 1,
        title: 'Параметри',
        description: (
            <>
   <h2><center>Курс "Параметри"</center></h2>
   <p>Онлайн курс «Параметри» складається з 14 уроків. У курсі розглядаються рівняння та нерівності з параметром, починаючи від найпростіших і до високого рівня.</p> Цей курс створено для учнів, які мають фундаментальну базу математичних знань і націлені на високй бал НМТ. 
   <p></p>Кожен урок курсу складається з авторських відеолекцій, конспектів, домашніх завдань та відеоаналізів до них. Слухачі курсу можуть приєднуватися до наших прямих етерів. Крім того, курс вміщує симуляції НМТ. Підтримка здійснюється у телеграм-групі курсу.<p></p>
   
            </>
        ),
        payLink: 'https://www.ipay.ua/ua/bills/online-kursy-podgotovki-k-vno-po-matematike-online',
        options: [
            'Реєстрація:закрита',
            'Тривалість:до дня НМТ ',
            'Кількість:14 уроків',
            'Ціна: 999 грн'
        ],
        features: [ 
            features.INDIVIDUAL,
            features.QUESTIONS,
            features.SUPPORT1,
            features.PREPARATION,
            features.VIDEO2,

        ]
    } 
];
 
const Courses = () => {
    const [about, setAbout] = useState(null);
    const [selected, setSelected] = useState(null);
    const [modalData, setModalData] = useState(null);

    const ref = createRef();

    const onAboutClick = (info, id) => {
        setAbout(info);
        setSelected(id);
        const top = ref.current.offsetTop + ref.current.getBoundingClientRect().height;
        window.scrollTo(0, top - 60);
    }

    return (
        <div className={styles.container} id="courses">
            <h4 className={styles.title}>Курси</h4>
            <div className={styles.list} ref={ref}>
                {courses.map(course => (
                    <div key={course.id} className={[styles.item, selected === course.id ? styles.active : 'default'].join(' ')}>
                        <div className={styles.header}>
                            <div className={styles.name}>{course.title}</div>
                            <div className={`${styles.price} ${course.newPrice && styles.crossLine}`}>
                                {course.price} 
                            </div>
                            {course.newPrice && (
                                <div className={styles.price} style={{ marginTop: 0 }}>
                                    {`${course.newPrice} грн`}
                                </div>        
                            )}
                        </div>
                        <ul className={styles.dottedList}>
                            {course.options.map(option => {
                                const dotSplitted = option.split(':');
                                return (
                                    <li key={option}>
                                        {dotSplitted.length === 2 ? (
                                            <>
                                                {<b>{dotSplitted[0]}: </b>}
                                                {<span>{dotSplitted[1]}</span>}
                                            </>
                                        ) : option}
                                    </li>
                                )}
                            )}
                        </ul>
                        <div className={styles.about} onClick={() => onAboutClick(course.about, course.id)}>
                            Дізнатися більше...
                        </div>
                        <button type="button" className={styles.registration} onClick={() => setModalData({ ...course })}>
                            Зареєструватися
                            <span /><span /><span /><span />
                        </button>
                    </div>
                ))}
                <h4 className={styles.title}>Спеціальні пропозиції</h4>
                {specialOffers.map(offer => (
                    <div key={offer.id} className={styles.offer}>
                        <div className={styles.offerTitle}>{offer.title}</div>
                        <div className={styles.seperatedFlexItems}>
                            <ul className={styles.dottedList}>
                                {offer.options.map(option => {
                                    const dotSplitted = option.split(':');
                                    return (
                                        <li key={option}>
                                            {dotSplitted.length === 2 ? (
                                                <>
                                                    {<b>{dotSplitted[0]}: </b>}
                                                    {<span>{dotSplitted[1]}</span>}
                                                </>
                                            ) : option}
                                        </li>
                                    )}
                                )}
                            </ul>
                            <div className={styles.description}>{offer.description}</div>
                        </div>
                        <button type="button" className={`${styles.registration} ${styles.mini}`} onClick={() => setModalData({ ...offer })}>
                            Зареєструватися
                            <span /><span /><span /><span />
                        </button>
                    </div>
                ))}
            </div>
            <AnimatePresence>
                {about && (
                    <motion.div
                        {...popupAnimation}
                        className={styles.expandMore}
                        onClick={() => {
                            setAbout(null);
                            setSelected(null);
                            window.scrollTo(0, ref.current.offsetTop - 100);
                        }}
                    >
                        <div className={styles.closeIcon}><RiCloseCircleLine /></div>
                        <div className={styles.courseHead}>{about.title}</div>
                        <div className={styles.courseContent}>{about.content}</div>
                    </motion.div>
                )}
                {modalData && <Modal modalData={modalData} onClose={() => setModalData(null)} />}
            </AnimatePresence>
        </div>
    );
}

export default Courses;
